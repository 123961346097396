import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#fff',
    fontFamily: 'serif !important',
    // textTransform: "capitalize",
    fontWeight: 500,
    marginBottom: 6,
    [`@media (min-width: 400px) and (max-width: 700px)`]: {
      color: '#000'
    },
    [`@media (min-width: 350px) and (max-width: 400px)`]: {
      color: '#000'
    },
  },
  optional: {
    // color: theme.palette.KenColors.neutral100,
  },
  labelWrap: {
    marginBottom: 2,
  },
  asterisk: {
    color: 'red',
  },
}));
export default function KenTextLabel(props) {
  const classes = useStyles();
  const { label, required, optionalLabel, helperText, instruction } = props;

  return (
    <div className={classes.labelWrap}>
      <Grid container justify="space-between">
        <Grid item>
          {instruction && (
            <Typography className={classes.label}>{instruction} </Typography>
          )}
          <Typography className={classes.label}>
            {label}{' '}
            {required ? <span className={classes.asterisk}>*</span> : ''}
            {helperText && (
              <span>
                <Tooltip title={helperText}>
                  <HelpOutlineIcon
                    sx={{
                      height: '12px !important',
                      width: '12px',
                      marginLeft: '3px',
                      marginBottom: '-2px',
                    }}
                  />
                </Tooltip>
              </span>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.optional} variant="subtitle1">
            {!required && optionalLabel ? '' : ' '}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
