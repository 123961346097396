import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import OtpInput from "react-otp-input";
import KenButton from "./GlobalComponents/KenButton";
import { SendOTP, VerifyLeadOTP } from "./Utils/apiServices";
import KenLoader from "./GlobalComponents/KenLoader";
// import { LOGO, PUBLIC_BACKGROUND_IMAGE_URL } from "../../../Helpers/constants";

const useStyles = makeStyles((theme) => ({
  formLayout: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    position: 'relative'
  },
  otpHeader: {
    display: "flex",
    position: "relative",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  backIcon: {
    height: "26px",
    width: "auto",
    color: "#fff",
    [`@media (min-width: 350px) and (max-width: 700px)`]: {
      color: '#000',
    },
  },
  resend: {
    border: "none",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#07588f",
    cursor: "pointer",
    fontStyle: 'serif',
    marginTop: "20px !important",
  },
  otpContainerCard: {
    padding: "40px 50px",
    borderRadius: "20px !important",
    marginTop: "20px",
    height: "maxContent",
  },

  footerSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "60px",
  },
  otpContainerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  otpInputStyle: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    width: "48px !important",
    height: "64px",
    fontSize: "16px",
    margin: "0 8px",
    textAlign: "center",
    justifyContent: "center",
  },
  backButton: {
    color: "#000000",
    fontWeight: "400",
    fontSize: "14px",
    cursor: "pointer",
    textDecoration: "underline",
  },
  otpInputContainer: {
    position: 'relative',
    width: 'fit-content',
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      'margin': 0,
    },
  },
  otpInput: {
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    width: '48px !important',
    height: '64px',
    fontSize: '16px',
    margin: '0 8px',
    textAlign: 'center',
    justifyContent: 'center',
  },
}));

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
const VerifyOTP = ({ handleBackClick, email, data }) => {
  console.log(data,"adbsjbcsz")
  const classes = useStyles();
  const theme = useTheme();
  const [loader, setLoader] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [otp, setOtp] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [responseData, setResponseData] = useState();
  const [seconds, setSeconds] = useState(5);
  const [timerRunning, setTimerRunning] = useState(false);
  const isMdScreen = useMediaQuery('(min-width: 768px)');

  console.log(responseData, "SaszASa");

  useEffect(() => {
    let timer;

    if (timerRunning && seconds > 0) {
      timer = setTimeout(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [timerRunning, seconds]);

  const handleStartTimer = () => {
    setSeconds(5); // Reset the timer to 5 seconds
    setTimerRunning(true); // Start the timer
  };

  useEffect(() => {
    if (seconds === 0) {
      // Redirect when the timer ends
      alert("Redirected to the Application Page.");
      window.open(
        `https://apply.theaims.ac.in/login?AIMS_Level_of_Study__c=${
          responseData?.account?.AIMS_Level_of_Study__c
        }&Name=${
          responseData?.account?.FirstName + " " + responseData?.account?.LastName
        }&ContactId=${responseData?.account?.Id}&Email=${
          responseData?.account?.PersonEmail
        }&Phone=${responseData?.account?.PersonMobilePhone}&access_token=${responseData?.accessToken?.token}`,
        "_self"
      );
    }
  }, [seconds]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
    };
    // window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  let COOL_DOWN_COUNT_IN_SECONDS = 2 * 60;
  const countDownRef = useRef();
  const [countDown, setCountDown] = useState(
    Date.now() + COOL_DOWN_COUNT_IN_SECONDS * 1000
  );

  const mobileScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const otpChange = (value) => {
    setOtp(value);
  };

  const resendOtp = async (data) => {
    try {
      setLoader(true);
      const res = await SendOTP(email, data);
      if (res?.success) {
        setLoader(false);
        setCountDown(Date.now() + COOL_DOWN_COUNT_IN_SECONDS * 1000);
        countDownRef.current.api.start();
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setLoader(false);
      enqueueSnackbar("Something went wrong while sending OTP", {
        variant: "error",
      });
    }
  };

  const handleVerifyClick = async (event) => {
    try {
      event.preventDefault(); // Prevent default form submission behavior

      // Call VerifyLeadOTP API with email and otp
      setLoader(true);

      console.log(otp=='', "otp");
      if (otp == '') {
        setLoader(false);
        enqueueSnackbar("Please enter OTP", {
          variant: "error",
        });
      } else {
        // console.log(otp, "OTP");
        const res = await VerifyLeadOTP({
          email: email,
          otp: otp,
          type: "Lead",
          leadId: data?.leadId,
        });

        console.log(res, "sdsfsf")
      const isSeparateFormat = res.hasOwnProperty('0');
      console.log(isSeparateFormat, "isSeparateFormat")

      if(isSeparateFormat) {
        const properResponse = res;

        const responseData = {
          "userName": properResponse.userName,
          "userId": properResponse.userID,
          "userEmail": properResponse.userEmail,
          "userDob": properResponse.userDob,
          "success": false,
          "OTP": properResponse.OTP,
          "levelOfStudy": "",
          "errorMessages": "Invalid OTP"
        };

        setOtp('');
        enqueueSnackbar(responseData.errorMessages, {
          variant: "error"
        })
        setLoader(false);
      } else {
        if (!res || !res?.success) {
          setOtp('');
          return enqueueSnackbar(res?.errorMessage || 'Something went wrong', {
            variant: 'error',
          });
          
        }

        if (res?.success) {
          setLoader(false);
          setResponseData(res);
          setShowSuccessMessage(true);
          handleStartTimer();
        } else {
          // Handle error response
          setLoader(false);
          enqueueSnackbar(res.errorMessage || "Failed to verify OTP", {
            variant: "error",
          });
          setOtp('');
        }
      }

        // if (res?.success) {
        //   setLoader(false);
        //   setResponseData(res);
        //   setShowSuccessMessage(true);
        //   handleStartTimer();
        // } else {
        //   // Handle error response
        //   setLoader(false);
        //   enqueueSnackbar(res.errorMessage || "Failed to verify OTP", {
        //     variant: "error",
        //   });
        // }
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setLoader(false);
      enqueueSnackbar(error?.response?.data?.result?.errorMessage || "Something went wrong while verifying OTP", {
        variant: "error",
      });
      setOtp('');
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Typography className={classes.resend}>
          <span style={{ color: isMdScreen ? "#fff" : '#000', fontFamily: 'serif' }}>Did not receive it yet? </span>
          <span
            onClick={() => {
              resendOtp(data);
              countDownRef.current.api.start();
            }}
          >
            <b>
              <u>Resend it</u>
            </b>
          </span>
        </Typography>
      );
    } else {
      return (
        <Typography
          color={"#07588f"}
          fontWeight={"bold"}
          fontSize={"14px"}
          margin={"10px auto"}
        >
          {pad(`${minutes}`, 2)}:{pad(`${seconds}`, 2)}
        </Typography>
      );
    }
  };

  useEffect(() => {
    if (countDownRef) {
      countDownRef.current.api.start();
    }
  }, [countDown]);

  return (
    <>
      {loader && <KenLoader />}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh", // Set the height to 100% of the viewport height
        }}
      >
        <Grid
          component={"div"}
          className="bg-white h-100 mx-auto"
          maxWidth={"360px"}
        >
          {showSuccessMessage ? (
            <>
              <Box>
                <img src="https://flamestorageken42.blob.core.windows.net/flame-storage-ken42/aims.gif" alt="AIMS Logo" width={'100'} style={{padding: '20px', position: 'relative'}} />
              </Box>
              {timerRunning && (
                <Typography style={{ fontSize: "14px", position: 'relative', color:  isMdScreen ? '#fff' : '#000' }}>
                  Redirecting to Admission Portal in <b>{seconds}</b>
                </Typography>
              )}
              <Typography style={{ fontSize: "14px", position: 'relative', color: isMdScreen ? '#fff' : '#000' }}>
                Thank you for your interest in AIMS Institute! Our admission
                team will contact you shortly. Stay tuned for a call on your
                registered phone number. To proceed to the application form,
                kindly click on the following link:
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  position: 'relative',
                  color: '#fff'
                }}
              >
                <Link
                  style={{ marginTop: "6px" }}
                  href="https://apply.theaims.ac.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </Link>
                <Typography
                  style={{
                    fontSize: "14px",
                    marginTop: "10px",
                    marginLeft: "5px",
                    color: isMdScreen ? '#fff' : '#000'
                  }}
                >
                  Additionally, feel free to visit our
                  <Link
                    style={{
                      marginTop: "6px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                    href="https://www.theaims.ac.in/
                  "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Website
                  </Link>
                  for more information.We look forward to assisting you further
                  on your academic journey!
                </Typography>
              </div>
            </>
          ) : (
            <>
              <Box>
                <img src="https://flamestorageken42.blob.core.windows.net/flame-storage-ken42/aims.gif" alt="AIMS Logo" width={'100'} style={{padding: '20px', position: 'relative'}} />
              </Box>
              <form onSubmit={handleVerifyClick}>
                <div
                  className={classes.formLayout}
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <div className={classes.otpHeader}>
                    <IconButton
                      style={{ position: "relative", left: "0" }}
                      onClick={handleBackClick}
                    >
                      <ArrowBackIcon className={classes.backIcon} />
                    </IconButton>
                    {/* <img src={LOGO} alt="Logo" height={50} width={30} /> */}
                    <p className={classes.resend}>
                      <span style={{ color: isMdScreen ? "#fff" : '#000', fontFamily: 'serif' }}>An OTP has been sent to</span>{" "}
                      {email}
                    </p>
                  </div>
                  <Card className={classes.otpContainerCard}>
                    <OtpInput
                      numInputs={4}
                      value={otp}
                      onChange={(otpValue) => otpChange(otpValue)}
                      renderInput={(inputProps, index) => (
                        <div className={classes.otpInputContainer}>
                          <input 
                            {...inputProps} 
                            key={index} 
                            type="number" 
                            className={classes.otpInput}
                          />
                        </div>
                      )}
                      containerStyle={classes.otpContainerStyle}
                      inputStyle={classes.otpInputStyle}
                    />
                  </Card>
                  <center>
                    <Countdown
                      ref={countDownRef}
                      date={countDown}
                      renderer={renderer}
                    />
                  </center>
                  <Box component={"div"} className={classes.footerSection}>
                    <KenButton
                      type='submit'
                      variant="primary"
                      // onClick={handleVerifyClick}
                      style={{ margin: "25px", color: "white !important", background: 'linear-gradient(114.33deg, #d55f2c, #8a286d)' }}
                    >
                      Submit
                    </KenButton>
                  </Box>
                </div>
              </form>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export default VerifyOTP;
