import DoneIcon from "@mui/icons-material/Done";
import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import ErrorIcon from '../../Assets/Icons/error.png';
import KenIcon from "../KenIcon";
import KenTextField from "../KenTextField";
import KenTextLabel from "../KenTextLabel";

const useStyles = makeStyles({
  errorStyles: {
    backgroundColor: "#ffffff",
  },
  successMessage: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#158467",
  },
});

export default function KenInput(props) {
  const {
    label,
    required,
    optionalLabel,
    error,
    ui,
    success,
    disableKeyDown,
    hideLabel = false,
    helperText,
    minDate,
    disabled,
    ...otherProps
  } = props;
  const classes = useStyles();

  return (
    <>
      <Box width="100%">
        {!hideLabel && (
          <KenTextLabel
            label={label}
            required={required}
            optionalLabel={optionalLabel}
            labelClassName={props.labelClassName}
            helperText={helperText}
          />
        )}
        <Box display="flex" alignItems="flex-start">
          <KenTextField
            disableKeyDown={disableKeyDown}
            error={error}
            disabled={disabled}
            minDate={minDate}
            {...otherProps}
            label=""
          />
        </Box>
        {success && (
          <Box display="flex" alignItems="flex-start" pt={1}>
            <Typography component="span">
              {success.icon || (
                <DoneIcon fontSize="small" style={{ color: "#158467" }} />
              )}
            </Typography>
            <Typography
              component="span"
              className={classes.successMessage}
              pl={1}
            >
              {success.message}
            </Typography>
          </Box>
        )}
      </Box>
      {ui && ui?.divider && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}
    </>
  );
}
