import axios from "axios";

const apiBaseUrl = "https://aims-api-prod.ken42.com/";
// const apiBaseUrl = "https://aims-node-dev-api.ken42.com/";
// const apiBaseUrl = "http://localhost:4000/";

const SendOTP = async (email, payload) => {
  const endpoint = `/otpservice/send?email=${email}&type=Lead`;
  const path = `v1/lead/post?endpoint=${encodeURIComponent(endpoint)}`;
  const res = await axios.post(`${apiBaseUrl}${path}`, payload);
  return res.data;
};

const VerifyLeadOTP = async (payload) => {
  const path = `v1/lead/post?endpoint=/otpservice/verify`;
  const res = await axios.post(`${apiBaseUrl}${path}`, payload);
  return res.data;
};

export { SendOTP, VerifyLeadOTP };
