import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976D2", // customize your primary color
    },
    secondary: {
      main: "#FF4081", // customize your secondary color
    },
  },
  // other theme customization options...
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const GlobalThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default GlobalThemeProvider;
