import DoneIcon from "@mui/icons-material/Done";
import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PhoneInput from "react-phone-input-2";
import KenError from "../KenError";
import KenTextLabel from "../KenTextLabel";
// import { DOC_URL } from '../../Constants/constant';
import { parsePhoneNumberFromString } from "libphonenumber-js";
// import ErrorIcon from '../../Assets/';
import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  inputFieldLabel: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "100%",
    textTransform: "capitalize",
    color: "#505F79",
  },
  phoneContainer: {
    display: "flex",
    "& .phone-input": {
      fontFamily: "Poppins,sans-serif",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "100%",
      color: "#505F79",
      padding: "16px",
      order: 2,
      background: "#F3F5F7",
      border: 0,
      height: "45px",
      width: "100% !important",
      //   57
      borderRadius: "4px",
      marginLeft: "11px",
      "&::placeholder": {
        fontSize: "16px",
        lineHeight: "100%",
        color: "#A8AFBC",
        fontFamily: "Poppins,sans-serif",
      },
    },
    "& .phone-dropdown": {
      position: "relative",
      order: 1,
      border: 0,
      background: "#F3F5F7",
      "&.open": {
        background: "#F3F5F7",
        "& .selected-flag": {
          background: "#F3F5F7",
        },
      },
      "& .selected-flag": {
        width: "50px",
        padding: "10px",
        "&:hover": {
          background: "#F3F5F7",
        },
      },
    },
  },
}));

export default function KenPhoneInput(props) {
  const { error, success, ui, setTrigger, trigger, setIsValid, nepalForm } = props;
  const classes = useStyles();

  const validatePhoneNumber = (inputPhoneNumber, countryCode) => {
    // Replace 'XX' with the actual country code you want to validate against.
    // For example, 'US' for the United States.
    try {
      const phoneNumberObject = parsePhoneNumberFromString(
        inputPhoneNumber,
        countryCode
      );
      if (phoneNumberObject && phoneNumberObject.isValid()) {
        // setError(name, {
        //   type: 'manual',
        //   message: `${name} is not valid!`,
        // });
        setIsValid && setIsValid(false);
      } else {
        setIsValid && setIsValid(true);
        setTrigger && trigger && setTrigger(!trigger);
      }
    } catch (error) {
      setIsValid && setIsValid(true);
      setTrigger && trigger && setTrigger(!trigger);
    }
  };

  return (
    <>
      <KenTextLabel
        label={props.label ? props.label : "Phone Number"}
        labelClassName={`${classes.inputFieldLabel} ${
          props.inputFieldLabelClass ? props.inputFieldLabelClass : ""
        }`}
        required={true}
      />
      <Box display="flex" alignItems="flex-start">
        <PhoneInput
          {...props}
          containerClass={`${classes.phoneContainer} ${
            props.phoneContainerClass ? props.phoneContainerClass : ""
          }`}
          inputClass={`${classes.inputFieldLabel} phone-input`}
          buttonClass="phone-dropdown"
          country={nepalForm ? 'np' : 'in'}
          preferredCountries={["in"]}
          placeholder="Enter Number"
          onChange={(phone, country, e, formattedValue) => {
            if (typeof props.onChange === "function") {
              if (phone.includes("+")) {
                props.onChange(phone);
                validatePhoneNumber(formattedValue, country?.countryCode);
              } else {
                props.onChange(`+${phone}`);
                validatePhoneNumber(formattedValue, country?.countryCode);
              }
            }
          }}
        />
      </Box>
      {props?.error && <KenError message={props?.error} single />}
      {success && (
        <Box display="flex" alignItems="flex-start" pt={1}>
          <Typography component="span">
            {success.icon || (
              <DoneIcon fontSize="small" style={{ color: "#158467" }} />
            )}
          </Typography>
          <Typography
            component="span"
            className={classes.successMessage}
            pl={1}
          >
            {success.message}
          </Typography>
        </Box>
      )}
      {ui && ui?.divider && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}
    </>
  );
}
