import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";

const StyledBox = styled(Box)((theme) => ({
  color: "#FC2C32",
}));
export default function KenError({ errors, field, type, message, single }) {
  if (single && message) {
    return (
      <StyledBox component="span">
        <Typography fontSize={"12px"} marginTop={"4px"} textAlign={"left"}>
          {message || "Please enter valid input"}
        </Typography>
      </StyledBox>
    );
  }

  return (
    <StyledBox component="span">
      <Typography>
        {errors[field] && errors[field]?.type === type ? message : ""}
      </Typography>
    </StyledBox>
  );
}
